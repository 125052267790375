<template>
	<div class="mt-1">
		<v-row>
			<v-col cols="4">
				<v-file-input
					:disabled="disabled || existing"
					v-model="initialUploaded.upload_picture"
					label="Upload Pictures"
					accept=".jpg, .png, .jpeg, .pdf"
					multiple
					prepend-icon="mdi-paperclip"
					dense
					outlined	
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							small
							close
							@click:close="removeFile(index, 'upload_picture')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>	
				<v-list v-if="this.currUser.is_president === '1'">
					<v-list-item v-if="existingInitialUploaded.upload_picture.length > 0">
				<v-list-item-content>
					{{ existingInitialUploaded.upload_picture[existingInitialUploaded.upload_picture.length - 1].title }}
				</v-list-item-content>
				<v-list-item-action>
					<v-icon @click="retrieveUploaded(existingInitialUploaded.upload_picture[existingInitialUploaded.upload_picture.length - 1].url)">
						mdi-eye
					</v-icon>
				</v-list-item-action>
			</v-list-item>
				</v-list>
				<v-list v-else>
					<v-list-item v-for="(item, index) in existingInitialUploaded.upload_picture" :key="index">
						<v-list-item-content>
							{{item.title}}
						</v-list-item-content>
						<v-list-item-action>
							<v-icon @click="retrieveUploaded(item.url)">
								mdi-eye
							</v-icon>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-col>
			
			<v-col cols="4">
				<v-file-input
					:disabled="disabled || existing"
					v-model="initialUploaded.upload_loi"
					label="LOI File"
					accept=".pdf, .doc, .docx"
					multiple
					dense
					outlined
					prepend-icon="mdi-paperclip"
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							close
							small
							@click:close="removeFile(index, 'upload_loi')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>
				<v-list v-if="this.currUser.is_president === '1'">
					<v-list-item v-if="existingInitialUploaded.upload_loi.length > 0">
				<v-list-item-content>
					{{ existingInitialUploaded.upload_loi[existingInitialUploaded.upload_loi.length - 1].title }}
				</v-list-item-content>
				<v-list-item-action>
					<v-icon @click="retrieveUploaded(existingInitialUploaded.upload_loi[existingInitialUploaded.upload_loi.length - 1].url)">
						mdi-eye
					</v-icon>
				</v-list-item-action>
			</v-list-item>
				</v-list>
				<v-list v-else>
					<v-list-item v-for="(item, index) in existingInitialUploaded.upload_loi" :key="index">
						<v-list-item-content>
							{{item.title}}
						</v-list-item-content>
						<v-list-item-action>
							<v-icon @click="retrieveUploaded(item.url)">
								mdi-eye
							</v-icon>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-col>
							
			<v-col cols="4">
				<v-file-input
					:disabled="disabled || existing"
					v-model="initialUploaded.upload_supp_docs"
					label="Upload Supporting Documents"
					accept=".pdf, .doc, .docx"
					multiple
					dense
					outlined
					prepend-icon="mdi-paperclip"	
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							close
							small
							@click:close="removeFile(index, 'upload_supp_docs')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>
				<v-list v-if="this.currUser.is_president === '1'">
					<v-list-item v-if="existingInitialUploaded.upload_supp_docs.length > 0">
				<v-list-item-content>
					{{ existingInitialUploaded.upload_supp_docs[existingInitialUploaded.upload_supp_docs.length - 1].title }}
				</v-list-item-content>
				<v-list-item-action>
					<v-icon @click="retrieveUploaded(existingInitialUploaded.upload_supp_docs[existingInitialUploaded.upload_supp_docs.length - 1].url)">
						mdi-eye
					</v-icon>
				</v-list-item-action>
			</v-list-item>
				</v-list>
				<v-list v-else>
					<v-list-item v-for="(item, index) in existingInitialUploaded.upload_supp_docs" :key="index">
						<v-list-item-content>
							{{item.title}}
						</v-list-item-content>
						<v-list-item-action>
							<v-icon @click="retrieveUploaded(item.url)">
								mdi-eye
							</v-icon>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-col>
		</v-row>
		<!--Kaizer Certificate Upload-->
		<v-row>
			<v-col cols="4">
				<v-file-input
					:disabled="disabled"
					v-model="initialUploaded.upload_kaizer"
					label="Kaizer Tool"
					accept=".pdf, .doc, .docx"
					multiple
					prepend-icon="mdi-paperclip"
					dense
					outlined	
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							close
							small
							@click:close="removeFile(index, 'upload_kaizer')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>
				<v-list v-if="this.currUser.is_president === '1'">
					<v-list-item v-if="existingInitialUploaded.upload_kaizer.length > 0">
				<v-list-item-content>
					{{ existingInitialUploaded.upload_kaizer[existingInitialUploaded.upload_kaizer.length - 1].title }}
				</v-list-item-content>
				<v-list-item-action>
					<v-icon @click="retrieveUploaded(existingInitialUploaded.upload_kaizer[existingInitialUploaded.upload_kaizer.length - 1].url)">
						mdi-eye
					</v-icon>
				</v-list-item-action>
			</v-list-item>
				</v-list>
				<v-list v-else>
					<v-list-item v-for="(item, index) in existingInitialUploaded.upload_kaizer" :key="index">
						<v-list-item-content>
							{{item.title}}
						</v-list-item-content>
						<v-list-item-action>
							<v-row>
								<v-col cols="6">
							<v-icon @click="retrieveUploaded(item.url)">
								mdi-eye
							</v-icon>
							</v-col>
							<!-- <v-col cols="6">
							<v-icon >
								mdi-delete
							</v-icon>
						</v-col> -->
							</v-row>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-col>
		</v-row>
		<!--AML Certificate Upload-->
		<v-row>
			<v-col cols="4">
				<v-file-input
					:disabled="disabled"
					v-model="initialUploaded.upload_amla"
					label="AML Form"
					accept=".xlsx, .csv, .pdf"
					multiple
					prepend-icon="mdi-paperclip"
					
					dense
					outlined	
					>
					<template v-slot:selection="{ text, index }">
						<v-chip
							close
							small
							@click:close="removeFile(index, 'upload_amla')"
							>
							{{ text }}
						</v-chip>
					</template>
				</v-file-input>
				<v-list v-if="this.currUser.is_president === '1'">
					<v-list-item v-if="existingInitialUploaded.upload_amla.length > 0">
				<v-list-item-content>
					{{ existingInitialUploaded.upload_amla[existingInitialUploaded.upload_amla.length - 1].title }}
				</v-list-item-content>
				<v-list-item-action>
					<v-icon @click="retrieveUploaded(existingInitialUploaded.upload_amla[existingInitialUploaded.upload_amla.length - 1].url)">
						mdi-eye
					</v-icon>
				</v-list-item-action>
			</v-list-item>
				</v-list>
				<v-list v-else>
					<v-list-item v-for="(item, index) in existingInitialUploaded.upload_amla" :key="index">
						<v-list-item-content>
							{{item.title}}
						</v-list-item-content>
						<v-list-item-action>
							<v-icon @click="retrieveUploaded(item.url)">
								mdi-eye
							</v-icon>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-col>
			<v-col cols="2">
    <v-text-field
        v-model="salesProspectingForm.amla_risk_rating"
        :disabled="disabled"
        @input='amlScoring()'
        label="AML Rating"
        dense
        outlined
				:rules="genericRule"
        hide-details="auto"
        :style="disabled ? { fontWeight: 'bold'} : {}">
			</v-text-field>
				</v-col>
		<v-col cols="2">
    <v-text-field
        v-model="salesProspectingForm.amla_risk_profile"
        label="AML Profile"
        readonly
        disabled
        dense
        outlined
        hide-details="auto"
        :style="disabled ? { fontWeight: 'bold'} : {}">
			</v-text-field>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	props: ['disabled', 'existing'],
    computed: {
		...mapGetters({
      salesProspectingForm: 'salesProspecting/salesProspectingForm',
			initialUploaded: 'salesProspecting/initialUploaded',
			existingInitialUploaded: 'salesProspecting/existingInitialUploaded',
			genericRule: 'salesProspecting/genericRule',
			currUser: 'auth/currUser',
		}),
	},
	watch: {
	// ----------------------------------- WATCH WHEN A FILE IS CHOSEN VALIDATE FILE TYPES-----------------------------------------
		'initialUploaded.upload_picture'(newFiles) {
				this.handleFileValidation(newFiles, 'upload_picture', ['jpg', 'jpeg', 'png', 'pdf']);
			},
			'initialUploaded.upload_loi'(newFiles) {
				this.handleFileValidation(newFiles, 'upload_loi', ['pdf', 'doc', 'docx']);
			},
			'initialUploaded.upload_supp_docs'(newFiles) {
				this.handleFileValidation(newFiles, 'upload_supp_docs', ['pdf', 'doc', 'docx']);
			},
			'initialUploaded.upload_kaizer'(newFiles) {
				this.handleFileValidation(newFiles, 'upload_kaizer', ['pdf', 'doc', 'docx']);
			},
			'initialUploaded.upload_amla'(newFiles) {
				this.handleFileValidation(newFiles, 'upload_amla', ['xlsx', 'csv', 'pdf']);
			},
		},
	methods: {
		removeFile (index, property) {
			this.initialUploaded[property].splice(index, 1)
		},
		async retrieveUploaded (item) {
			const payload = {
				file: item
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			} else if (extension === 'xlsx') {
				//---------------------------- OPENING OF AML FORM IF XLSX ----------------------------------
				blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) 
			} else if (extension === 'csv') {
				//---------------------------- OPENING OF AML FORM IF CSV ----------------------------------
				blob = new Blob([file], { type: 'text/csv' }) 
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
		//--------------------- AUTOMATING AML PROFILE DISPLAY AND STORING DATA TO SALES PROSPECTING FORM ---------------------------
		amlScoring() {
    if(this.salesProspectingForm.amla_risk_rating < 13 && this.salesProspectingForm.amla_risk_rating >= 0){			 
        this.salesProspectingForm.amla_risk_profile = 'Low Risk'
    } else if(this.salesProspectingForm.amla_risk_rating >= 13 && this.salesProspectingForm.amla_risk_rating <= 19){
        this.salesProspectingForm.amla_risk_profile = 'Normal Risk'
    } else {
        this.salesProspectingForm.amla_risk_profile = 'High Risk'
    }
	},
	// ----------------------------------- VALIDATE FILES INPUTTED-----------------------------------------
	handleFileValidation(newFiles, fileType, types){
		//Checking if the file chosen is not null
		if(newFiles && newFiles.length > 0){ 
			const fileExtension = newFiles[0].name.split('.').pop().toLowerCase(); 
				//Checking if the file chosen is the accepted file type
				if (!types.includes(fileExtension)) { 
					//dispatch snackbar for alert
					this.$store.dispatch('app/setSnackBar',{'status': true, 'message':`Wrong File Type For ${fileType}: Only ${types.join(', ')} files are accepted.`,'messagetype':'error'});
					//remove the chosen file
					this.initialUploaded[fileType].splice(0)
        } else {
					//accept the chosen file
					this.initialUploaded[fileType] = newFiles
        }
			}
	},
	//check if it is the latest file uploaded
	isLastIndex(index){
		return index === this.existingInitialUploaded.upload_picture.length - 1 
	}
}
};
</script>
