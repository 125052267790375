<template>
  <div>
  <v-row>
    <v-col cols="12">
      <v-expansion-panels v-if="salesProspectingForm.scorecard !== ''" accordion>
        <v-expansion-panel
          v-for="(item, index) in salesProspectingForm.scorecard"
          :key="index"
          >	
          <v-expansion-panel-header>{{item.title}}<br><br>{{item.description}}</v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <v-simple-table>
                <template v-slot:default>
                                      <thead>
                    <tr>
                      <th class="text-left">
                                                  BUSINESS PARTNER SELECTION CRITERIA 
                      </th>
                      <th class="text-left">
                        METRICS
                      </th>
                      <th>
                        Points
                      </th>
                      <th>
                        Weight
                      </th>
                      <th>
                        Rating
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(criteria,index) in item.criteria" :key="index">
                      <td>	
                        {{criteria.name}}	
                      </td>
                      <td>
                        <p v-for="(metrics,index) in criteria.metrics" :key="index" style="margin:0px; padding:0px;">
                          {{metrics.text}}
                        </p>
                      </td>
                      <td>	
                        <v-input v-model.number="criteria.score" :rules="scoreCardRule">
                          <v-rating
                            v-model.number="criteria.score"
                            hide-details="auto"
                            :length="criteria.metrics.length"
                            :readonly="disabled"
                          ></v-rating>
                        </v-input>
                      </td>
                      <td>
                        {{criteria.weight}}%
                      </td>
											<!--computation with regards to business scoring-->
                      <td>
                        {{(criteria.score * (criteria.weight / criteria.metrics.length)).toFixed(2)}} 
                      </td>
                    </tr>
                    <tr>
                      <td style="border-left:none; border-right:none; border-bottom:none;"></td>
                      <td style="border-left:none; border-right:none; border-bottom:none;"></td>
                      <td><b>{{computePoints(item.criteria)}}</b></td>
                      <td><b>{{computeTotalWeight(item.criteria)}}%</b></td>
                      <td><b>{{computeTotalRating(item.criteria)}}</b></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
    </v-col>
    <!--Score Card Risk Rating-->
    <v-col cols="9">
      <h3 class="pt-2" style="float:right"><b v-if="salesProspectingForm.scorecard !== ''">FINAL RATING: {{computeFinalRating()}}%</b></h3>
    </v-col>
    <!--Score Card Risk Profiling-->
    <v-col cols="3">
      <h3 class="pt-2" style="float:right;">
      <b v-if="salesProspectingForm.scorecard !== '' && computeFinalRating() >= 90">
        RISK SCORING: <span style="color: green;">LOW</span>
      </b>
      <b v-else-if="salesProspectingForm.scorecard !== '' && computeFinalRating() >= 70 && computeFinalRating() < 90">
        RISK SCORING: <span style="color: orange;">MODERATE</span>
      </b>
      <b v-else>
        RISK SCORING: <span style="color: red;">HIGH</span>
      </b>
    </h3>

    </v-col>
  </v-row>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
props: ['update', 'disabled'],
  async created () {
  const resScoreCard = await this.$store.dispatch('scorecard/doGetScoreCard')
  if (!this.update) {
    this.$store.commit('salesProspecting/SET_SCORE_CARD', resScoreCard.data.result.scoreCard)
    
  }
},
  computed: {
  ...mapGetters({
          salesProspectingForm: 'salesProspecting/salesProspectingForm',
    scoreCardRule: 'salesProspecting/scoreCardRule'
  }),
},
  methods: {
      computePoints(item){
    if(typeof item !== undefined){
      var total = 0
      item.forEach(function(e){
        total += parseInt(e.score)
      })
      if(isNaN(total)){
        return ''
      }
      return total
    }
  }, 
  computeTotalWeight(item){
    if(typeof item !== undefined){
      var total = 0
      item.forEach(function(e){
        total += parseInt(e.weight)
      })
      return total
    }
  },
  computeTotalRating(item){
    if(typeof item !== undefined){
      var total = 0
      item.forEach(function(e){
        total += (parseInt(e.score) * (e.weight / e.metrics.length)) //computation with regards to business scoring
      })
      if(isNaN(total)){
        return ''
      }
      return total.toFixed(2)
    }
  },
 computeFinalRating(){
    if(this.salesProspectingForm.scorecard !== ''){
      var total = 0
      this.salesProspectingForm.scorecard.forEach(function(e){
        e.criteria.forEach(function(f){
          total += (parseInt(f.score) * (f.weight / f.metrics.length).toFixed(2)) //computation with regards to business scoring
        })
        
      })
      if(isNaN(total)){
        return ''
      }
      return total.toFixed(2)
    }
  }
  }
};
</script>
